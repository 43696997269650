import fetch from '@/utils/fetch';

/**
 * 初始化外呼坐席列表
 * @param params
 */
export function login(params) {
  return fetch({
    url: '/user/login',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getSeatInfoByUser(params) {
  return fetch({
    url: '/seat/account/getSeatInfoByUser',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function clickCall(params) {
  return fetch({
    url: '/call/clickCall',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getSeatListOfMerchantByPage(params) {
  return fetch({
    url: '/seat/account/getSeatListOfMerchantByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getSeatListOfMerchantDepartmentByPage(params) {
  return fetch({
    url: '/seat/account/getSeatListOfMerchantDepartmentByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getSeatInfoOfMerchant(params) {
  return fetch({
    url: '/seat/account/getSeatInfoOfMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getUserListOfMerchantByPage(params) {
  return fetch({
    url: '/merchant/getUserListOfMerchantByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}


export function editSeatAccountOfUser(params) {
  return fetch({
    url: '/seat/account/editSeatAccountOfUser',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getUserListNoBindSeatOfMerchant(params) {
  return fetch({
    url: '/seat/account/getUserListNoBindSeatOfMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getRecordListByMerchant(params) {
  return fetch({
    url: '/record/getRecordListByMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getRecordListByUseId(params) {
  return fetch({
    url: '/record/getRecordListByUseId',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getSeatAccountReportList(params) {
  return fetch({
    url: '/report/getSeatAccountReportList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getSeatMerchantReportList(params) {
  return fetch({
    url: '/report/getSeatMerchantReportList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function initCustomerInfo(params) {
  return fetch({
    url: '/customer/initCustomerInfo',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getCustomerInfoList(params) {
  return fetch({
    url: '/customer/getCustomerInfoList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getCustomerHighSeasList(params) {
  return fetch({
    url: '/customer/getCustomerHighSeasList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMyCustomerInfoList(params) {
  return fetch({
    url: '/customer/getMyCustomerInfoList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMyTaskCustomerInfoList(params) {
  return fetch({
    url: '/customer/getMyTaskCustomerInfoList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getTaskCustomerInfoList(params) {
  return fetch({
    url: '/customer/getTaskCustomerInfoList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getCustomerDetails(params) {
  return fetch({
    url: '/customer/getCustomerDetails',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createCustomer(params) {
  return fetch({
    url: '/customer/createCustomer',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editCustomer(params) {
  return fetch({
    url: '/customer/editCustomer',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editCustomerStatus(params) {
  return fetch({
    url: '/customer/editCustomerStatus',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editCustomerRemark(params) {
  return fetch({
    url: '/customer/editCustomerRemark',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function initRegion(params) {
  return fetch({
    url: '/system/initRegion',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function batchImportCustomerList(params) {
  return fetch({
    url: '/customer/batchImportCustomerList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function batchImportCustomerRepeatList(params) {
  return fetch({
    url: '/customer/batchImportCustomerRepeatList',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getCustomerFollowList(params) {
  return fetch({
    url: '/customer/getCustomerFollowList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function initCustomerFollow(params) {
  return fetch({
    url: '/customer/initCustomerFollow',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createCustomerFollow(params) {
  return fetch({
    url: '/customer/createCustomerFollow',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function customerAssignment(params) {
  return fetch({
    url: '/customer/customerAssignment',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function batchCustomerAssignment(params) {
  return fetch({
    url: '/customer/batchCustomerAssignment',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function batchCustomerDelete(params) {
  return fetch({
    url: '/customer/batchCustomerDelete',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function batchMyReceiveCustomer(params) {
  return fetch({
    url: '/customer/batchMyReceiveCustomer',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function white(params) {
  return fetch({
    url: '/telecom/sendPhoneCode',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function code(params) {
  return fetch({
    url: '/telecom/verifyCode',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function exportSeatAccountReportList(params) {
  const data = new FormData()
  for (const key in params) {
    if (params[key]) {
      data.append(key, params[key])
    }
  }
  return fetch({
    url: '/export/exportSeatAccountReportList',
    method: 'POST',
    responseType: 'blob',
    data: data
  }).then(res => {
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
    let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
    let a = document.createElement("a");
    a.href = url;
    a.download = '坐席报表';
    a.click();
    // 释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  })
}
export function exportSeatMerchantReportList(params) {
  const data = new FormData()
  for (const key in params) {
    if (params[key]) {
      data.append(key, params[key])
    }
  }
  return fetch({
    url: '/export/exportSeatMerchantReportList',
    method: 'POST',
    responseType: 'blob',
    data: data
  }).then(res => {
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
    let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
    let a = document.createElement("a");
    a.href = url;
    a.download = '坐席商家报表';
    a.click();
    // 释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  })
  // var form = document.createElement("form");
  // form.setAttribute("style", "display:none");
  // form.setAttribute("method", "post");
  // for (var item in params) {
  //   var input = document.createElement('input');
  //   input.setAttribute('type', 'hidden');
  //   if (params[item] !== null) {
  //     input.setAttribute('name', item);
  //     input.setAttribute('value', params[item]);
  //   }
  //   form.append(input);
  // }
  // form.setAttribute("action",
  //   process.env.VUE_APP_BASE_API + '/export/exportSeatMerchantReportList'
  // );
  // var body = document.createElement("body");
  // body.setAttribute("style", "display:none");
  // document.body.appendChild(form);
  // form.submit();
  // form.remove();
}

export function loadUserListOfMerchantByPage(params) {
  return fetch({
    url: '/user/getUserListOfMerchantByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editUserOfMerchant(params) {
  return fetch({
    url: '/user/editUserOfMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function manageDirectorDepartment(params) {
  return fetch({
    url: '/department/manageDirectorDepartment',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getUserInfoListByDepartmentId(params) {
  return fetch({
    url: '/department/getUserInfoListByDepartmentId',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteDepartmentUserRa(params) {
  return fetch({
    url: '/department/deleteDepartmentUserRa',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createDepartmentUserRa(params) {
  return fetch({
    url: '/department/createDepartmentUserRa',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getDepartmentTreeList(params) {
  return fetch({
    url: '/department/getDepartmentTreeList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteDepartment(params) {
  return fetch({
    url: '/department/deleteDepartment',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function updateDepartment(params) {
  return fetch({
    url: '/department/updateDepartment',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createDepartment(params) {
  return fetch({
    url: '/department/createDepartment',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getDepartmentNoBindUserList(params) {
  return fetch({
    url: '/department/getDepartmentNoBindUserList',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function updateDirectorDepartmentOfUser(params) {
  return fetch({
    url: '/department/updateDirectorDepartmentOfUser',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getRecordByUserIdAndSessionId(params) {
  return fetch({
    url: '/record/getRecordByUserIdAndSessionId',
    method: 'POST',
    data: {
      ...params
    }
  });
}


export function callerLineReport(params) {
  return fetch({
    url: '/telecom/phoneSmsSend',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getSaleCustomerHighSeasList(params) {
  return fetch({
    url: '/customer/getSaleCustomerHighSeasList',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function myReceiveCustomer(params) {
  return fetch({
    url: '/customer/myReceiveCustomer',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function giveUpCustomerToHighSea(params) {
  return fetch({
    url: '/customer/giveUpCustomerToHighSea',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getCustomerFollowPlanList(params) {
  return fetch({
    url: '/customer/getCustomerFollowPlanList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function completeCustomerFollowPlan(params) {
  return fetch({
    url: '/customer/completeCustomerFollowPlan',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function convertCustomerSaleHighSea(params) {
  return fetch({
    url: '/customer/convertCustomerSaleHighSea',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function modifyUserPassword(params) {
  return fetch({
    url: '/user/editUserPassword',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMerchantOfAccountInfo(params) {
  return fetch({
    url: '/merchant/getMerchantOfAccountInfo',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editSeatBindPhoneNumber(params) {
  return fetch({
    url: '/seat/account/editSeatBindPhoneNumber',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function exportTaskList(params) {
  const data = new FormData()
  for (const key in params) {
    if (params[key]) {
      data.append(key, params[key])
    }
  }
  return fetch({
    url: '/export/exportTaskCustomerList',
    method: 'POST',
    responseType: 'blob',
    data: data
  }).then(res => {
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
    let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
    let a = document.createElement("a");
    a.href = url;
    a.download = '任务客户列表';
    a.click();
    // 释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  })
}

export function editSeatAccountState(params) {
  return fetch({
    url: '/seat/account/modifySeatAccountState',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function exportRecordList(params) {
  const data = new FormData()
  for (const key in params) {
    if (params[key]) {
      data.append(key, params[key])
    }
  }
  return fetch({
    url: '/export/merchant/exportRecordList',
    method: 'POST',
    responseType: 'blob',
    data: data
  }).then(res => {
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
    let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
    let a = document.createElement("a");
    a.href = url;
    a.download = '通话记录列表';
    a.click();
    // 释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  })
}

export function modifyUserName(params) {
  return fetch({
    url: '/user/updateUserName',
    method: 'POST',
    data: {
      ...params
    }
  });
}