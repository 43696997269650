<template>
	<el-menu
		mode="vertical"
		:collapse="isCollapse"
		router
		active-text-color="#409EFF"
		:default-active="currentRoute"
	>
		<el-menu-item index="/call">
			<i class="el-icon-phone-outline"></i>
			<span slot="title">呼叫系统</span>
		</el-menu-item>
		<el-menu-item index="/userList" v-if="isAdmin">
			<i class="el-icon-user"></i>
			<span slot="title">用户列表</span>
		</el-menu-item>
		<el-menu-item index="/seat" v-if="isAdmin">
			<i class="el-icon-setting"></i>
			<span slot="title">坐席管理</span>
		</el-menu-item>
		<el-menu-item index="/dep-seat" v-if="isAdmin | hasDirector">
			<i class="el-icon-s-promotion"></i>
			<span slot="title">部门坐席管理</span>
		</el-menu-item>
		<el-menu-item index="/customer" v-if="isAdmin">
			<i class="el-icon-user"></i>
			<span slot="title">客户公海</span>
		</el-menu-item>
		<el-menu-item index="/customer-sea">
			<i class="el-icon-user"></i>
			<span slot="title">销售客户公海</span>
		</el-menu-item>
		<el-menu-item index="/customerList" v-if="isAdmin | hasDirector">
			<i class="el-icon-location-outline"></i>
			<span slot="title">客户列表</span>
		</el-menu-item>
		<el-menu-item index="/my-customer">
			<i class="el-icon-star-off"></i>
			<span slot="title">我的客户</span>
		</el-menu-item>
    <el-menu-item index="/taskCustomerList" v-if="isAdmin | hasDirector">
      <i class="el-icon-tickets"></i>
      <span slot="title">任务客户列表</span>
    </el-menu-item>
    <el-menu-item index="/my-task">
      <i class="el-icon-tickets"></i>
      <span slot="title">我的任务</span>
    </el-menu-item>
		<el-menu-item index="/department">
			<i class="el-icon-s-flag"></i>
			<span slot="title">部门列表</span>
		</el-menu-item>
		<el-menu-item index="/call-record" v-if="isAdmin | hasDirector">
			<i class="el-icon-service"></i>
			<span slot="title">通话记录</span>
		</el-menu-item>
		<el-menu-item index="/my-call-record">
			<icon-svg
				iconClass="shouye"
				style="margin-right: 5px; font-size: 18px; color: #909399"
			></icon-svg>
			<span slot="title">我的通话记录</span>
		</el-menu-item>
		<el-menu-item index="/seat-report">
			<icon-svg
				iconClass="wxbbaobiao"
				style="margin-right: 5px; font-size: 18px; color: #909399"
			></icon-svg>
			<span slot="title">呼叫系统坐席报表</span>
		</el-menu-item>
		<el-menu-item index="/merchant-report" v-if="isAdmin">
			<icon-svg
				iconClass="fl-baobiao"
				style="margin-right: 5px; font-size: 18px; color: #909399"
			></icon-svg>
			<span slot="title">呼叫系统商家报表</span>
		</el-menu-item>
	</el-menu>
</template>

<script>
	import { mapGetters, mapMutations } from 'vuex'
	export default {
		data() {
			return {
				currentRoute: this.$route.path,
				hasDirector: this.$store.getters.hasDirector,
				isAdmin: this.$store.getters.role.includes('admin'),
			}
		},
		computed: {
			...mapGetters(['sidebar']),
			isCollapse: function () {
				return !this.sidebar.opened
			},
		},
		created() {},
		methods: {
      setCurrentRoute () {
        this.currentRoute = this.$route.path;//关键   通过他就可以监听到当前路由状态并激活当前菜单
      }
    },
    watch: {
      $route () {
        this.setCurrentRoute()
      }
    },
	}
</script>

<style lang="scss" scoped></style>
