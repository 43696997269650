<template>
  <!-- <particles-bg type="lines" :bg="true" /> -->
  <div id="app">
    <keep-alive include="register">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { ParticlesBg } from "particles-bg-vue";

export default {
  name: "app",
  components: {},
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
