import { login } from '@/api';
// import {getUserInfo,getInfo} from '@/api/user/index';
import Cookies from 'js-cookie';

const caclRole = () => {
  const role = localStorage.getItem('role')
  if (role) {
    if (role === 'admin') {
      return ['admin']
    } else {
      return JSON.parse(role)
    }
  } else {
    return []
  }
}


const user = {
  state: {
    token: localStorage.getItem('token'),
    satoken: localStorage.getItem('token'),
    session: {},
    userId: localStorage.getItem('userId'),
    userName: localStorage.getItem('userName'),
    phone: localStorage.getItem('phone'),
    role: caclRole(),
    merchantName: localStorage.getItem('merchantName'),
    hasDirector: localStorage.getItem('hasDirector'),
  },

  mutations: {
    SET_HASDIRECTOR: (state, hasDirector) => {
      state.hasDirector = hasDirector;
      localStorage.setItem('hasDirector', hasDirector)
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      localStorage.setItem('token', token)
    },
    SET_SATOKEN: (state, satoken) => {
      state.satoken = satoken;
      localStorage.setItem('token', satoken)
    },
    SET_USER_ID: (state, val) => {
      state.userId = val;
      localStorage.setItem('userId', val)
    },
    SET_USER_NAME: (state, name) => {
      state.userName = name;
      localStorage.setItem('userName', name)
    },
    SET_PHONE: (state, phone) => {
      state.phone = phone;
      localStorage.setItem('phone', phone)
    },
    SET_ROLE: (state, val) => {
      state.role = val;
      localStorage.setItem('role', JSON.stringify(val))
    },
    SET_SESSION: (state, session) => {
      state.session = session
    },
    SET_MERCHANT_NAME: (state, name) => {
      state.merchantName = name
      localStorage.setItem('merchantName', name)
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const phone = userInfo.phone.trim();
      return new Promise((resolve, reject) => {
        // phone,userInfo.userPwd,userInfo.typeId
        login({
          phone: phone,
          password: userInfo.userPwd
        }).then(response => {
          console.log(response)
          commit('SET_SESSION', response);
          commit('SET_USER_ID', response.userId);
          commit('SET_TOKEN', response.token);
          commit('SET_USER_NAME', response.userName);
          commit('SET_PHONE', response.phone);
          commit('SET_MERCHANT_NAME', response.merchantName);
          if (response.hasDirector) {
            commit('SET_HASDIRECTOR', 1);
          } else {
            commit('SET_HASDIRECTOR', 0);
          }
          let role = []
          if (response.hasAdministrator == 1) {
            role.push('admin')
          }
          if (response.hasDirector) {
            role.push('director')
          }
          commit('SET_ROLE', role)
          document.title = response.merchantName;
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo({ "token": state.token }).then(data => {
          if (data) {
            commit('SET_SESSION', data.session);
            commit('SET_ROLES', data.roleList);
            Cookies.set('lastLoginMerchantId', data.session.lastLoginMerchantId);
            Cookies.set('lastLoginMerchantName', data.session.lastLoginMerchantName);
            commit('SET_NAME', data.session.userSessionDto.userName);
            resolve(data);
          } else {
            Cookies.remove("Admin-Token");
            Cookies.remove("currentModule");
            Cookies.remove("currentMenu");
            commit('SET_TOKEN', '');
            resolve();
          }
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '');
          commit('SET_SATOKEN', '');
          localStorage.clear();
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 前端 登出
    FedLogOut({ commit, state }) {
      return new Promise(resolve => {
        localStorage.clear();
        resolve(state);
      });
    },

    UpdateUserName({ commit }, userName) {
      return new Promise(resolve => {
        commit('SET_USER_NAME', userName);
      });
    },
  }
};

export default user;
