<template>
  <div>
    <svg @click="toggleClick" class="svg-icon hamburger" :class="{'is-active':isActive}" width="64" height="64" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 0H0V30H30V0Z" fill="white" fill-opacity="0.01"/>
      <path d="M5 6.875H25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 15H26.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 23.125H25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.7148 18.536L26.2503 15.0004L22.7148 11.4648" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'hamburger',
    props: {
      isActive: {
        type: Boolean,
        default: false
      },
      toggleClick: {
        type: Function,
        default: null
      }
    }
  }
</script>

<style scoped>
  .hamburger {
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transform: rotate(0deg);
    transition: .38s;
    transform-origin: 50% 50%;
  }

  .hamburger.is-active {
    transform: rotate(90deg);
  }
</style>
