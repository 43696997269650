import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/404/notfound.vue'
import Layout from '../views/layout/Layout.vue'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: '',
    component: Layout,
    children: [
      { path: '/', name: 'Home', component: Home },
      { path: '/about', name: 'about', component: () => import('../views/About.vue'), meta: { roles: ['admin'] } },
      { path: '/call', name: 'call', component: () => import('../views/call/index.vue') },
      { path: '/seat', name: 'seat', component: () => import('../views/seat/index.vue'), meta: { roles: ['admin'] } },
      { path: '/call-record', name: 'call-record', component: () => import('../views/record/call-record.vue'), meta: { roles: ['admin', 'director'] } },
      { path: '/my-call-record', name: 'my-call-record', component: () => import('../views/record/my-call-record.vue') },
      { path: '/merchant-report', name: 'merchant-report', component: () => import('../views/record/merchant-report.vue'), meta: { roles: ['admin'] } },
      { path: '/seat-report', name: 'seat-report', component: () => import('../views/record/seat-report.vue') },
      { path: '/customer', name: 'customer', component: () => import('../views/customer/list.vue'), meta: { roles: ['admin'] } },
      { path: '/userList', name: 'userList', component: () => import('../views/user/list.vue'), meta: { roles: ['admin'] } },
      { path: '/customerList', name: 'customerList', component: () => import('../views/customer/customerList.vue'), meta: { roles: ['admin', 'director'] } },
      { path: '/my-customer', name: 'my-customer', component: () => import('../views/customer/mine.vue') },
      { path: '/taskCustomerList', name: '/taskCustomerList', component: () => import('../views/customer/taskCustomerList.vue') },
      { path: '/my-task', name: 'my-task', component: () => import('../views/customer/task.vue') },
      { path: '/addCustomer', name: 'add-customer', component: () => import('../views/customer/add.vue') },
      { path: '/view-customer', name: 'customerDetails', component: () => import('../views/customer/view.vue') },
      { path: '/modify-customer', name: 'modify-customer', component: () => import('../views/customer/modify.vue') },
      { path: '/importCustomer', name: 'import-customer', component: () => import('../views/customer/import.vue') },
      { path: '/department', name: 'department', component: () => import('../views/department/index.vue') },
      { path: '/customer-sea', name: 'customer-sea', component: () => import('../views/customer/sea.vue') },
      { path: '/dep-seat', name: 'dep-seat', component: () => import('../views/seat/dep-seat.vue'), meta: { roles: ['admin', 'director'] } },
    ]
  },
  { path: '/404', name: 'NotFound', component: NotFound },
  { path: '/login', name: 'login', component: () => import('../views/login.vue') },

  { path: '*', redirect: '/404', hidden: true },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
