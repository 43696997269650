const getters = {
  token: state => state.user.token,
  hasDirector: state => state.user.hasDirector,
  session: state => state.user.session,
  userName: state => state.user.userName,
  userId: state => state.user.userId,
  phone: state => state.user.phone,
  role: state => state.user.role,
  merchantName: state => state.user.merchantName,
  sidebar: state => state.app.sidebar,
};
export default getters
