<template>
	<el-breadcrumb class="app-levelbar" separator=">">
		<el-breadcrumb-item v-for="(item,index)  in levelList" :key="index">
			<router-link v-if='item.redirect==="noredirect"||index==levelList.length-1' to="" class="no-redirect">
				{{item.name}}</router-link>
			<router-link v-else to="" class="no-redirect">{{item.name}}</router-link>
		</el-breadcrumb-item>
	</el-breadcrumb>
</template>

<script>
	export default {
		created() {
			this.getBreadcrumb();
		},
		data() {
			return {
				levelList: null,
			};
		},
		methods: {
			getBreadcrumb() {
				let matched = this.$route.matched.map((item) => {
					let temp = {};
					temp.path = item.path ? item.path : '/';
					temp.name = item.meta.nameZH ? item.meta.nameZH : '首页';
					return temp;
				});

				const first = matched[0];

				if (first && first.name !== '首页') {
					matched = [{ name: '首页', path: '/dashboard' }].concat(matched);
				}

				this.levelList = matched;
			},
		},
		watch: {
			$route() {
				this.getBreadcrumb();
			},
		},
		created() {
			this.getBreadcrumb();
		},
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.app-levelbar.el-breadcrumb {
		display: inline-block;
		font-size: 14px;
		line-height: 50px;
		margin-left: 10px;
		.no-redirect {
			color: #97a8be;
			cursor: text;
		}
	}
</style>
