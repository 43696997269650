<template>
  <div class="home">欢迎 <span style="color:#409EFF">{{ adminName }}</span>  登录使用{{merchantName}}系统~~~</div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      adminName: this.$store.getters.adminName,
      merchantName: this.$store.getters.merchantName,
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding: 30px;
}
</style>
