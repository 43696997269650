<template>
	<div class="app-wrapper" :class="{ hideSidebar: !sidebar.opened }">
		<div class="topbar-wrapper">
			<topbar></topbar>
		</div>
		<div class="sidebar-wrapper">
			<sidebar class="sidebar-container"></sidebar>
		</div>
		<div class="main-container">
			<app-main></app-main>
		</div>
	</div>
</template>
<script>
	import { Topbar, Navbar, Sidebar, AppMain } from '@/views/layout'
	export default {
		name: 'layout',
		components: {
			Topbar,
			Navbar,
			Sidebar,
			AppMain,
		},
		computed: {
			sidebar() {
				return this.$store.state.app.sidebar
			},
		},
	}
</script>

<style lang="scss" scoped>
	.app-wrapper {
		position: relative;
		height: 100%;
		width: 100%;
		&.hideSidebar {
			.sidebar-container {
				width: 50px;
				overflow: hidden;
				user-select: none;
			}
			.main-container {
				margin-left: 50px;
				transition: margin-left 0.28s ease-out;
			}
		}
		.topbar-wrapper {
			background: #fff;
			height: 60px;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 999;
		}
		.sidebar-container {
			transition: width 0.28s ease-out;
			width: 185px;
			height: 100%;
			position: fixed;
			top: 70px;
			bottom: 0;
			left: 0;
			z-index: 998;
			border-radius: 0px;
			border-top-right-radius: 10px;
			overflow-y: auto;
			user-select: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		.main-container {
			height: 100%;
			transition: margin-left 0.28s ease-out;
			margin-left: 190px;
			padding-top: 65px;
		}
	}
</style>
