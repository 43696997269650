<template>
	<div class="grid-content">
		<div class="logo">
			<!-- <img :src="img" alt="" /> -->
			<span class="head-title" @click="linktoIndex">{{ merchantName }}</span>
			<hamburger
				class="hamburger-container"
				:toggleClick="toggleSideBar"
				:isActive="sidebar.opened"
			></hamburger>
		</div>

		<div class="top-info">
			<!-- <el-tooltip content="查看个人资料" placement="top">
        <el-button
          type="text"
          style="color: white; font-size: 18px; margin-right: 20px"
          @click="linkToAccount(id)"
          >{{ adminName }}</el-button
        >
      </el-tooltip> -->
			<el-badge :is-dot="customerFollowPlanList.length > 0" class="item">
				<el-button
					circle
					type="warning"
					icon="el-icon-chat-square"
					size="small"
					@click="handleOpenDialog"
				></el-button>
			</el-badge>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <span style="color: white; font-size: 18px; margin-right: 20px">{{
            userName
          }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item divided @click.native="enableEditPassword">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="enableAccount" v-if="hasAdminRole">
            <span style="display:block;">查看余额</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="enableEditUserName">
            <span style="display:block;">修改用户名</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

			<el-tooltip content="退出系统" placement="top">
				<el-button
					type="text"
					style="color: white; font-size: 18px; margin-right: 20px"
					@click="logout"
					>退出</el-button
				>
			</el-tooltip>
		</div>
		<el-dialog
			title="跟进计划"
			:visible.sync="centerDialogVisible"
			z-index="998"
		>
			<el-table :data="customerFollowPlanList" stripe width="100%" height="500">
				<el-table-column
					prop="customerName"
					label="客户姓名"
					align="center"
				></el-table-column>
				<el-table-column
					prop="followTypeName"
					label="跟进类型"
					align="center"
				></el-table-column>
				<el-table-column
					prop="followTime"
					label="跟进时间"
					align="center"
				></el-table-column>
				<el-table-column
					prop="followStateName"
					label="跟进状态"
					align="center"
				></el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="handleFinishFollowPlan(scope.row)"
							>已完成</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
    <el-dialog title="编辑用户密码" :visible.sync="dialogForModifyUserPassword"  center width="40%" :modal-append-to-body="false">
      <el-form
          ref="modifyPasswordForm"
          :model="modifyPasswordForm"
          label-width="100px"
          :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="原密码:" prop="oldPassword">
              <el-input
                  type="password"
                  v-model="modifyPasswordForm.oldPassword"
                  placeholder="请输入原密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码:" prop="newPassword">
              <el-input
                  type="password"
                  v-model="modifyPasswordForm.newPassword"
                  placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="原密码:" prop="confirmPassword">
              <el-input
                  type="password"
                  v-model="modifyPasswordForm.confirmPassword"
                  placeholder="请再次输入新密码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForModifyUserPassword = false"
        >取消
        </el-button
        >
        <el-button type="primary" @click="handleModifyUserPassword">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="商家账户详情" :visible.sync="dialogAccount" :modal-append-to-body="false">
      <el-descriptions class="margin-top" title="" border :column="2">
        <el-descriptions-item label="商家名称">{{merchantAccountInfo.merchantName}}</el-descriptions-item>
        <el-descriptions-item label="账户余额">{{merchantAccountInfo.balance}}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>

    <el-dialog title="编辑昵称" :visible.sync="dialogForModifyUserName"  center width="40%" :modal-append-to-body="false">
      <el-form
          ref="modifyUserNameForm"
          :model="modifyUserNameForm"
          label-width="100px"
          :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="新昵称:" prop="userName">
              <el-input
                  type="input"
                  v-model="modifyUserNameForm.userName"
                  placeholder="请输入昵称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForModifyUserName = false"
        >取消
        </el-button
        >
        <el-button type="primary" @click="handleModifyUserName">确认</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	// import {  AppMain } from '@/views/layout';
	import 'element-ui/lib/theme-chalk/display.css'
	import { mapGetters, mapMutations } from 'vuex'
	import Hamburger from '@/components/Hamburger'
	import Vue from 'vue'
	import {
		completeCustomerFollowPlan,
		getCustomerFollowPlanList,
    modifyUserPassword,
    getMerchantOfAccountInfo,
    modifyUserName
	} from '../../api'
	export default {
		name: 'topbar',
		data() {
			return {
				centerDialogVisible: false,
				customerFollowPlanList: [],
				messageCount: 0,
				messageData: [],
				id: '',
				img: require('@/assets/images/logo.jpg'),
        dialogForModifyUserPassword: false,
        dialogForModifyUserName: false,
        dialogAccount: false,
        hasAdminRole: this.$store.getters.role.includes('admin'),
        modifyPasswordForm: {
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        },
        merchantAccountInfo: {
          merchantName: "",
          balance: ""
        },
        modifyUserNameForm: {
          userName: ""
        },
        rules: {
          oldPassword: [
            {required: true, message: "请输入原密码", trigger: "blur"},
          ],
          newPassword: [
            {required: true, message: "请输入新密码", trigger: "blur"},
          ],
          confirmPassword: [
            {required: true, message: "请再次输入新密码", trigger: "blur"},
          ],
          userName: [
            {required: true, message: "请输入新昵称", trigger: "blur"},
          ]
        },
			}
		},
		components: {
			Hamburger,
		},
		computed: {
			...mapGetters(['sidebar', 'userName', 'merchantName']),
		},
		created() {
			this.loadCustomerFollowPlanList()
		},
		methods: {
      handleModifyUserName() {
        const newUserName = this.modifyUserNameForm.userName;
        modifyUserName(this.modifyUserNameForm).then((data) => {
          if (data) {
            this.$notify({
              type: 'success',
              title: '成功',
              message: '昵称已更改',
            })
            this.$store.dispatch('UpdateUserName', newUserName.trim());
            this.modifyUserNameForm.userName = '';
            this.dialogForModifyUserName = false;
          }
        })
      },
      handleModifyUserPassword() {
        modifyUserPassword(this.modifyPasswordForm).then((data) => {
          if (data) {
            this.$notify({
              type: 'success',
              title: '成功',
              message: '密码修改成功,请重新登录',
            })
            this.$store.dispatch('FedLogOut').then((data) => {
              console.log(data)
              location.reload()
            })
          }
        })
      },
      enableAccount() {
        //获取商家账户信息
        getMerchantOfAccountInfo().then((data) => {
          if (data) {
            this.merchantAccountInfo = data
            this.merchantAccountInfo.merchantName = this.merchantName
          }
        })
        this.dialogAccount = true;
      },
      enableEditPassword() {
        this.dialogForModifyUserPassword = true;
      },
      enableEditUserName() {
        this.dialogForModifyUserName = true;
      },
			handleFinishFollowPlan(row) {
				console.log('row===', row)
				completeCustomerFollowPlan({ followId: row.followId }).then((data) => {
					if (data) {
						this.$notify({
							type: 'success',
							title: '成功',
							message: '已完成跟进计划....',
						})
						this.loadCustomerFollowPlanList()
					}
				})
			},
			handleOpenDialog() {
				this.centerDialogVisible = true
			},
			loadCustomerFollowPlanList() {
				getCustomerFollowPlanList({ current: 1, size: 100, params: {} }).then(
					(data) => {
						console.log(data)
						this.customerFollowPlanList = data.records
					}
				)
			},
			linktoIndex() {
				this.$router.push('/')
			},
			toggleSideBar() {
				this.$store.dispatch('ToggleSideBar')
			},
			logout() {
				this.$confirm(`确定退出吗？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						this.$store.dispatch('FedLogOut').then((data) => {
							console.log(data)
							location.reload()
						})
					})
					.catch(() => {})
			},
			clickTab(key) {
				this.setModule(key)
			},
			linkToAccount(id) {
				this.$router.push(`/account/${id}`)
			},
		},
		watch: {},
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.grid-content {
		background: #409EFF;
		height: 60px;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.hamburger-container {
			margin: 0px 20px;
		}
		.logo {
			padding-left: 20px;
			font-size: 30px;
			background: rgba($color: #409EFF, $alpha: 0.5);
			display: flex;
			.head-title {
				display: inline-block;
				cursor: pointer;
				border-radius: 4px;
				padding: 0 10px;
				user-select: none;
			}
			img {
				width: 60px;
				height: 60px;
			}
		}
		.svg-container {
			flex: 0;
			font-size: 20px;
		}
		.top-logo {
			width: 264px;
			height: 80px;
			text-align: center;
			line-height: 80px;
		}
		.top-logo img {
			width: 60px;
			vertical-align: middle;
		}
		.top-info {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 12px;
		}
	}
</style>
