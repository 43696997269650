import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
import { Message } from 'element-ui'
import '@/assets/iconfont/iconfont'
import IconSvg from '@/components/Icon-svg/index.vue'
import { Loading } from 'element-ui';

Vue.component('icon-svg', IconSvg);
Vue.config.productionTip = false
Vue.use(Loading.directive);

const whiteList = ['/login'];
router.beforeEach(async (to, from, next) => {
  if (store.getters.userId) {
    if (to.path == '/login') {
      next({ path: '/' });
    } else {
      let metaInfo = JSON.stringify(to.meta)
      const role = store.getters.role
      let canView = false
      if (to.meta.roles) {
        const toMetaRoles = to.meta.roles
        toMetaRoles.map(item => {
          if (role.includes(item)) {
            console.log('incued===========')
            canView = true
          }
        })
      }
      if (metaInfo !== "{}" && to.meta.roles && !canView) {
        Message.error(`未获得访问该页面访问权，请联系管理员!`)
        next(from)
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login');
    }
  }

}
)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
